import React from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap"
import content from "../../data/content"
import Logo from "../../images/solace-developers-logo-white.png"

export default class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div>
        <Navbar color="dark" dark expand="lg" className="main-nav">
          <Container fluid={true}>
            <NavbarBrand href="/">
              <img
                src={Logo}
                alt="Solace Developers"
                width="125px"
                height="36px"
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mx-auto" navbar>
                <NavItem></NavItem>
                <NavItem>
                  <NavLink
                    href="https://solace.community"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                  >
                    {content.navs.main.link_community}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://solace.com/events/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                  >
                    {content.navs.main.link_events}
                  </NavLink>
                </NavItem>
                <NavLink
                  href="https://tutorials.solace.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {content.navs.main.link_api_tutorials}
                </NavLink>
                <NavItem>
                  <NavLink
                    href="https://docs.solace.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                  >
                    {content.navs.main.link_docs}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://solace.com/downloads/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                  >
                    {content.navs.main.link_downloads}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://codelabs.solace.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nowrap"
                  >
                    {content.navs.main.link_codelabs}
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav>
                <NavItem>
                  <a
                    className="buttonSmall"
                    href="https://console.solace.cloud/login"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {content.navs.main.link_cta_login}
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

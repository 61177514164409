const content = {
  sl_translate: "sl_all",
  // NAVIGATION
  navs: {
    main: {
      link_community: "Community",
      link_events: "Events",
      link_api_tutorials: "API Tutorials",
      link_docs: "Docs",
      link_downloads: "Downloads",
      link_codelabs: "Codelabs",
      link_cta_login: "Login",
    },
    secondary: {
      link_what_is_ps: "What is PubSub+?",
      link_api_protocols: "API and Protocols",
      link_use_cases: "Use Cases",
      link_resources: "Resources",
      link_cta_trynow: "Try Now",
    },
  },
  // HERO
  hero: {
    header: "PubSub+ for Developers",
    plead:
      "Create event-driven apps and microservices on any platform with whatever language, open protocols, and APIs you choose.",
    ctadocker: "Get Started with Docker",
    ctacloud: "Get Started with Cloud",
  },
  // INTRO
  intro: {
    title: "What is PubSub+?",
    paragraph_1: {
      p1_link: "Solace PubSub+ Platform",
      p1_content:
        "is a complete event streaming solution that enables the design, deployment, and operation of Event-Driven Architecture (EDA) across hybrid cloud, multi-cloud and IoT environments. The heart of the platform consists of",
      p1_1_link: "PubSub+ Event Brokers",
      p1_1_content:
        ", which connect to form Event Meshes that dynamically route events in real-time, and the",
      p1_2_link: " Event Portal",
      p1_2_content: " which is pioneering how organizations manage their EDA.",
    },
    paragraph_2: {
      p2_content:
        "Solace.dev is the home base for developers using PubSub+ Platform. You'll find information about our Messaging APIs, Platform APIs and more in the sections below. Have questions? Let us know over in the",
      p2_link: "Solace Community",
    },
  },
  // APIS AND PROTOCOLS
  apisprotocols: {
    title: "Messaging APIs and Protocols",
    paragraph_1: {
      p1_content: "Solace PubSub+ Event Brokers have",
      p1_link: "built-in support",
      p1_1_content:
        "for a variety of proprietary and open standard protocols and APIs, so you can create and connect apps with whatever language, open protocols and APIs you choose, without worrying about translation.",
    },
    paragraph_2: {
      p2_content:
        "Solace messaging APIs offer uniform client access to all Solace PubSub+ capabilities and qualities of service, and are available for C, .NET, iOS, Java, JavaScript, JMS, Python and Node.js. Solace also supports popular open protocols like AMQP, JMS, MQTT, REST and WebSocket, and open APIs such as Paho and Qpid.",
    },
  },
  // USE CASES
  usecases: {
    title: "Use Cases",
    tab_microservices: "Microservices",
    tab_event_mesh: "Event Mesh",
    tab_iot: "IoT",
    tab_messaging: "Messaging",
    tab_cloud: "Cloud",
    tab_streaming: "Streaming",
    learn_more_get_started: {
      cat_usecase: "Use Case",
      cat_blog: "Blog",
      cat_codelabs: "Codelabs",
      cat_demo: "Demo",
      cat_video: "Video",
      cat_sample: "Sample",
      cat_doc: "Doc",
    },
    // USE CASES - MICROSERVICES
    microservices: {
      title: "Microservices",
      paragraph_1:
        "Microservices are a software development technique in which an application is broken down into a set of services that function as a processing pipeline. Decomposing an application into multiple microservices can bring several benefits, including improved development speed, an easier to understand code base, enablement of continuous delivery, improved scalability, and better fault isolation. In many cases, application processing is kicked off by a web request via an API gateway using the request/reply pattern. Once the request is received, the microservices work in concert behind the gateway to process the request.",
      subheading_1: "Enable event-driven microservices",
      paragraph_2:
        "To provide business value, microservices need to effectively communicate while maintaining their independence and singularity of purpose. Your default may be to use an orchestration layer to decouple your RESTful HTTP services from your microservices, and to synchronize interactions between your microservices (Figure 1). But as your system scales and the need for your microservices to work in parallel increases, so too does your need to take an event-driven approach enabled by asynchronous, publish-subscribe messaging patterns (Figure 2). This is the approach enabled by PubSub+ Event Brokers.",
      paragraph_3:
        "The brokers sit between your microservices, enabling them to publish events to the broker and subscribe to events published to the broker by other microservices. This approach carries many advantages:",
      list_items: {
        item_1:
          "It’s easy to add, remove and move services within the system, without disrupting the system.",
        item_2:
          "It’s simple to scale individual services or groups of services to handle variability in load (rather than having to scale the entire system which is often necessary when microservices are tied together with synchronous communication patterns like request-reply).",
        item_3:
          "It’s non-blocking: asynchronous microservice threads don’t get blocked while waiting for a response (as they do with synchronous patterns).",
        item_4:
          "It enables resiliency and error-handling: PubSub+ Event Brokers can persist all messages to guaranteed delivery, and can serve as a source of truth in the case of failures, enabling rapid disaster recovery without risk of message loss.",
      },
      learn_more: {
        title: "Learn More",
        link_1: "PubSub+ for Event-Driven Microservices",
        link_2: "REST vs. Messaging for Microservices – Which One is Best?",
        link_3: "Communication Between Microservices: Why You Need Messaging",
        link_4: "A Walk Through The World of Microservices",
        link_5:
          "Unlock the Potential of Spring Cloud Stream Apps with an Advanced Event Broker",
      },
      get_started: {
        title: "Get Started",
        link_1:
          "Microgateways and turning your existing RESTful requests/replies into events",
        link_2: "Spring Samples",
        link_3: "Solace Spring Cloud Stream Binder",
      },
    },
    // USE CASES - EVENTMESH
    eventmesh: {
      title: "Event Mesh",
      paragraph_1_1: "An",
      paragraph_1_2: "event mesh",
      paragraph_1_3: "is to your event-driven applications",
      paragraph_1_4: "what a service mesh",
      paragraph_1_5:
        "is to your RESTful applications: an architectural layer that allows events from one application to be dynamically routed and received by other applications, across diverse systems, clouds and protocols. This layer is composed of a network of",
      paragraph_1_6: "advanced event brokers",
      paragraph_1_7:
        ". The capabilities of the brokers that compose the mesh determine the capabilities and quality of the mesh.",
      subheading_1: "Building an event mesh with PubSub+",
      paragraph_2: "An event mesh built with PubSub+ Event Brokers is:",
      list_items: {
        item_1_bold: "Dynamic",
        item_1:
          "events and other messages are self-routing, self-learning, self-healing, and built for automated and efficient event streaming between event producing and event consuming apps, wherever they run.",
        item_2_bold: "Open",
        item_2:
          "natively supports multiple open standard protocols and APIs, for an open ecosystem.",
        item_3_bold: "Simple",
        item_3:
          " the mesh can be created, managed and monitored through a single console.",
        item_4_bold: "Everywhere",
        item_4:
          "can be deployed natively on premises, in private clouds and in public clouds (AWS, GCP, Azure), as an appliance, software and as a service.",
      },
      learn_more: {
        title: "Learn More",
        link_1: "Service Mesh, Meet Event Mesh",
        link_2: "The Age of the Advanced Event Broker",
      },
      get_started: {
        title: "Get Started",
        link_1: "Create a global, multi-cloud event mesh",
      },
    },
    // USE CASES - IOT
    iot: {
      title: "Internet of Things",
      paragraph_1:
        "IP-addressed applications typically send and receive data to devices through an intermediary node like an IoT gateway, and the default communication pattern is request-reply over REST/HTTP (Figure 1).",
      paragraph_2:
        "For simple IoT projects and pilots that tend to be very focused and small scale, this will be the path of least resistance, and it will probably work just fine. But as you move from pilot to production in an enterprise environment, and as your application requirements grow more complex, the benefits of an event-driven approach enabled by event brokers and messaging patterns like publish-subscribe increase.",
      subheading_1: "Build IoT apps that are flexible and scale",
      paragraph_3:
        "PubSub+ Event Brokers can sit between your applications, IoT Gateways, back-end systems and cloud services, enabling a variety of communication patterns between them (publish-subscribe, streaming, queuing and request-reply), and over a variety of open standard protocols and APIs (MQTT, AMQP, JMS, WebSockets, REST/HTTP). This carries a number of advantages:",
      paragraph_4:
        "It enables event-driven communication between diverse endpoints and networks (devices, gateways, legacy applications, cloud-based microservices, data lakes, analytics engines) without requiring you to build complex protocol translations into your applications (protocol translation is done by the broker).",
      list_items: {
        item_1:
          "It makes for a highly scalable system: brokers can be vertically and horizontally scaled to handle exponential increases in load and connection counts.",
        item_2:
          "It enables efficient command and control of edge devices by back-end applications: (1) every device and application in the system is addressable through PubSub+ topic routing; (2) broker-enabled protocol translation lets back-end apps send commands over JMS while gateways/devices can receive the commands over other protocols (like MQTT, AMQP, REST/HTTP); and (3) messaging capabilities supported by the broker like fan-out and filtering enable back-end systems to send commands to individual, all, or select groups of edge devices.",
        item_3:
          "It’s fault tolerant: PubSub+ Event Brokers enable multiple qualities of service including guaranteed delivery for IoT scenarios where every message must be delivered—even to disconnected subscribers or those that can’t handle large volumes of data at a time.",
      },
      learn_more: {
        title: "Learn More",
        link_1: "PubSub+ for IoT",
        link_2:
          "Designing, Documenting and Testing Event APIs for IoT Platforms",
        link_3: "IoT, Raspberry Pi, and Solace",
        link_4: "Our IoT Partner Solutions Program",
      },
      get_started: {
        title: "Get Started",
        link_1: "MQTT Messaging Samples",
      },
    },
    // USE CASES - MESSAGING
    messaging: {
      title: "Messaging",
      paragraph_1_1:
        "Messaging refers to the passing of data between two applications via an intermediary broker (",
      paragraph_1_2: "message-oriented middleware",
      paragraph_1_3:
        "). Messaging communication patterns like publish-subscribe, queuing and streaming can be differentiated from other communications patterns like request-reply over REST/HTTP in that messaging enables the decoupling/loose coupling of applications (Figure 4), and messages are generally sent asynchronously, whereas point-to-point request-reply patterns tend to support synchronous interactions between applications that are tightly coupled (Figure 3).",
      paragraph_2:
        "If you’re experienced with messaging, you may be familiar with open source tools like RabbitMQ, ActiveMQ or Kafka, or a commercial product like IBM MQ, TIBCO EMS (for on-prem deployments), AWS SQS/SNS or Azure Service Bus (cloud-native). Each messaging solution has its pros and cons, but a modern event broker like PubSub+ fits the bill for enterprise use cases that demand high performance and reliability across public clouds, private clouds, and datacenters, the flexibility to deploy anywhere, and the need to leverage a variety of open standard protocols and APIs.",
      subheading_1: "Leverage modern messaging",
      paragraph_3:
        "PubSub+ Event Brokers are uniquely designed to support modern development practices (microservices, reactive, polyglot) and architectures (event-driven, hybrid cloud, multi-cloud), with support for the publish-subscribe messaging pattern (along with the traditional queuing and request/reply patterns), dynamic event streaming, and a host of additional features that ensure enterprise-grade performance and reliability no matter the demands of load and scale.",
      get_started: {
        title: "Get Started",
        link_1: "Exploring Solace Native APIs - The Basics",
        link_2:
          "How to Event-Enable your REST Architecture with Solace PubSub+",
        link_3: "Core Solace API Concepts",
        link_4: "Messaging Developer Guide",
      },
    },
    // USE CASES - CLOUD
    cloud: {
      title: "Cloud",
      paragraph_1:
        "To enable communication among cloud-native applications, microservices and cloud services, your default may be to use the request-reply pattern over REST/HTTP, or to leverage messaging services provided by the public cloud you’re working in – AWS SQS/SNS, Google Cloud Pub/Sub, Azure Service Bus, etc.",
      paragraph_2:
        "If you’re working in a single public cloud and your application has little need to consume data produced elsewhere (on premises, in private clouds, or in other public clouds), one of these approaches may work just fine. But as your application requirements grow to include publishing and subscribing to data streams across multiple public clouds and/or private clouds/datacenters, the benefits of using a cross-cloud and hybrid-cloud message broker increase.",
      subheading_1:
        "Stream data across public clouds, private clouds and hybrid clouds",
      paragraph_3:
        "PubSub+ Event Brokers support event-driven applications and architectures across hybrid-cloud, multi-cloud and IoT environments, with support for the publish-subscribe messaging pattern (along with the traditional queuing and request/reply patterns), dynamic event streaming, and a host of additional features that ensure enterprise-grade performance and reliability no matter the demands of load and scale.",
      paragraph_4_1:
        "PubSub+ Event Brokers can be deployed natively in AWS, GCP and Azure, as well as in private clouds/datacenters, and the brokers can be connected to form a dynamic event mesh for event-driven communication across all environments (see ",
      paragraph_4_2: "Event Mesh",
      paragraph_4_3: " use case).",
      paragraph_5_1: "PubSub+ Event Broker Cloud",
      paragraph_5_2:
        "offers enterprise-grade messaging as a service, on-demand in your favorite public and virtual private clouds.",
      paragraph_6:
        "Here are some of the cloud-related capabilities and benefits you can unlock:",
      list_items: {
        item_1:
          "Stream data from your premises to the cloud: enable secure, reliable, real-time data movement between legacy and cloud applications over WebSocket, AMQP 1.0, JMS or REST/HTTP.",
        item_2:
          "Stream data across private and public clouds; work inside the virtual private clouds (VPCs) offered by public cloud providers to maintain security and control.",
        item_3:
          "Leverage WAN optimization for high throughput and low latency between cloud regions (over long-haul links) with limited bandwidth and/or round-trip times.",
        item_4:
          "Filter data at the edge: only send events and other data to the cloud when you have applications in the cloud that are interested in the data (save $).",
        item_5:
          "Benefit from built-in fault tolerance and replication, both with fast failover, to ensure data is never interrupted and messages never get lost.",
        item_6:
          "“Lift and shift” existing applications directly to the cloud leveraging JMS support.",
      },
      links: {
        links_open_bracket: "(",
        links_close_bracket: ")",
        links_comma: ", ",
        link_1: "Sign up with PubSub+ Cloud",
        link_2_1: "Explore the ",
        link_2_2: "Quick Starts",
        link_2_3: "AWS",
        link_2_4: "GCP",
        link_2_5: "Azure",
        link_2_6: "Spring Cloud",
        link_2_7: "Spring Boot",
        link_2_8: "Kubernetes",
        link_3_1: "Review the ",
        link_3_2: "Integration Guides",
        link_3_3: "AWS",
        link_3_4: "Kafka",
        link_3_5: "Spring",
        link_3_6: "MuleSoft",
      },
    },
    streaming: {
      title: "Streaming",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  },
  // RESOURCES
  resources: {
    title: "Resources",
    tutorials: {
      title: "API Tutorials",
      description:
        "Get up-to-speed in sending and receiving messages over open APIs and protocols, Solace Messaging APIs, and Pivotal Platform.",
    },
    ttyd: {
      title: "Engage with an Advocate",
      description:
        "Chat with a developer advocate on your schedule! We want to meet you and hear about your Solace experience.",
    },
    github: {
      title: "GitHub Samples",
      description:
        "Check out our Solace samples for Spring, JMS, MQTT, AMQP, JavaScript, and more.",
    },
    connector_hub: {
      title: "Connector Hub",
      description:
        "Get a jump start plugging PubSub+ into AWS, Kafka, Spark, IBM WebSphere, and much more.",
    },
    training_certification: {
      title: "Training & Certifications",
      description: "Get up to speed on our managed messaging service.",
    },
    sdkperf: {
      title: "SDKPerf",
      description:
        "SDKPerf is a command line tool for validating performance, checking configuration, and exploring features associated with your PubSub+ message broker.",
    },
    developer_community: {
      title: "Developer Community",
      description: "Technical community for PubSub+.",
    },
    codelabs_workshops: {
      title: "Codelabs & Workshops",
      description:
        "Get guided, hands on coding experience with PubSub+ Codelabs.",
    },
  },
  // FOOTER
  footer: {
    products: {
      link_1: "Products",
      link_2: "PubSub+ Event Broker: Software",
      link_3: "PubSub+ Event Broker: Appliance",
      link_4: "PubSub+ Event Broker: Cloud",
    },
    company: {
      link_1: "Company",
      link_2: "Careers",
      link_3: "Leadership",
      link_4: "Customers",
      link_5: "Partners",
      link_6: "Events",
      link_7: "Legal",
    },
    developers: {
      link_1: "Developers",
      link_2: "Docs",
      link_3: "Blog",
      link_4: "Community",
      link_5: "Support",
      link_6: "Contact",
      link_7: "Log In",
    },
  },
}

export default content
